
export function calculateLoanPayment(amount, rate, period) {
    let interest = rate / 100 / 12;
    if (amount && rate && period && interest) {
        let monthly =  amount * (interest * Math.pow(1 + interest, period)) / (Math.pow(1 + interest, period) - 1);
        return Math.ceil(monthly * 100) / 100;
    }
    return 0;
}

export function calculateLoanTotal(amount, rate, period) {
    let interest = rate / 100 / 12;
    if (amount && rate && period && interest) {
        let monthly = amount * (interest * Math.pow(1 + interest, period)) / (Math.pow(1 + interest, period) - 1);
        return Math.floor(monthly * period * 100) / 100;
    }
    return 0;
}