<template>
  <b-row no-gutters class="mb-3">
    <b-col cols="12" md="5" lg="4" class="d-none d-md-flex align-items-center">
      <b-img rounded fluid src="/images/agm-news-item.png" alt="AGM" class="m-0 p-0" />
    </b-col>
    <b-col class="d-flex align-items-start flex-column justify-content-center ml-0 ml-md-3">
      <div class="py-3 px-2 w-100">
        <h4>Notice of Annual General Meeting</h4>
        <h5>For the year until 30 September 2023</h5>
        <p>Wednesday 28th February 2024 at 1pm</p>
        <router-link to="/agm" class="btn btn-success">Read More</router-link>
      </div>
    </b-col>
  </b-row>
</template>

<script>
export default {
  name: "AGM"
}
</script>

<style scoped>
</style>