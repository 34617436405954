export function formatToCurrency(value, decimals = 2) {
    return (value ?? 0).toLocaleString("en-GB", {
        style: "currency",
        currency: "GBP",
        minimumFractionDigits: decimals,
    });
}

export function formatToPercentage(value) {
    return `${value ?? 0}%`;
}

export function formatToMonths(value) {
    let months = value ?? 0;
    return `${value ?? 0} month${months === 1 ? "" : "s"}`;
}