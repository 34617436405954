<template>
  <div class="prize-draw-results mb-3">
    <b-link
        class="w-100 btn btn-success"
        to="/savings/prize-saver/draws">
      We are pleased to announce the winners for the {{ latestDrawDate }} PrizeSaver draw! ... [Read More]
    </b-link>
  </div>
</template>

<script>
import {prizeSaverDraw} from "@/data/prize-saver-draw";

export default {
  name: "PriceDrawResults",
  computed: {
    latestDrawDate() {
      let dates = prizeSaverDraw.map(x => new Date(`${x.date}-01`)).sort((a, b) => b - a);
      if (dates.length > 0) {
        // format date as MMM YYYY
        return dates[0].toLocaleDateString('en-GB', {month: 'long', year: 'numeric'});
      }
      return 'latest';
    }
  }
}
</script>

<style>
</style>