<template>
  <div v-if="newsItems.length" class="news-alerts accordion mb-3">
    <div class="mb-1" v-for="(item, index) in newsItems" :key="index">
      <b-button
          block
          :class="`text-left d-flex align-items-center justify-content-between ${selectedIndex === index ? 'active' : ''}`"
          variant="outline-info"
          @click="updateIndex(index)"
      >
        <span>{{ item.title }}</span>
        <span class="small">Read More</span>
      </b-button>
      <b-collapse
          :id="`news-item-${index}`"
          accordion="news-items"
          :visible="selectedIndex === index"
      >
        <b-card no-body class="mt-2">
          <b-card-body body-class="news-content" v-html="item.content"/>
        </b-card>
      </b-collapse>
    </div>
  </div>
</template>

<script>
import NewsItems from "@/data/news.js";
export default {
  name: "NewsAlerts",
  data() {
    return {
      news: NewsItems,
      selectedIndex: null
    };
  },
  computed: {
    newsItems() {
      return this.news.filter(x => {
        if (x.visible) {
          return x.visible();
        }
        return true;
      })
    }
  },
  mounted() {
    // get index for first news item that as open equals true
    const index = this.newsItems.findIndex(x => x.open);
    if (index > -1) {
      this.updateIndex(index);
    }
  },
  methods: {
    updateIndex(index) {
      if (this.selectedIndex === index) {
        this.selectedIndex = null;
        return;
      }
      this.selectedIndex = index;
    }
  }
}
</script>

<style>
.news-alerts .news-content p:last-child {
  margin-bottom: 0 !important;
}
</style>